export const columns = [
	{
		prop: 'carNum',
		label: '自编号',
		width: '140px',
	},
	{
		prop: 'status',
		label: '车辆状态',
		width: '100px',
	},
	{
		prop: 'carModelName',
		label: '车型名称',
		width: '280px',
	},

	{
		prop: 'licensePlateNum',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'stockAreaName',
		label: '库存地',
		width: '180px',
	},
	{
		prop: 'platformChannelName',
		label: '平台渠道',
		width: '140px',
	},
	{
		prop: 'assetBelongCompanyName',
		label: '资产所属公司',
		width: '140px',
	},
	{
		prop: 'carColor',
		label: '颜色',
	},
	{
		prop: 'carMileage',
		label: '公里数（KM）',
		width: '120px',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		width: '120px',
	},
	{
		prop: 'driverName',
		label: '司机名称',
		width: '120px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '120px',
	},
	{
		prop: 'belongCompanyName',
		label: '所属公司',
		width: '280px',
	},
	{
		prop: 'annualAuditDateEnd',
		label: '年检截至日期',
		width: '140px',
	},
	{
		prop: 'clivtaDateEnd',
		label: '交强险结束日期',
		width: '140px',
	},
	{
		prop: 'carInsuranceDateEnd',
		label: '商业险结束日期',
		width: '140px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '250px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '80px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择车牌号',
			label: '车牌号',
			attr: 'licensePlateNums',
			type: 'multi-select',
			isCustom: true,
			options: [],
		},
		{
			placeholder: '请选择维保专员',
			label: '维保专员',
			attr: 'maintainerId',
			type: 'select',
			options: [],
		},
		// {
		// 	placeholder: '请选择车辆状态',
		// 	label: '车辆状态',
		// 	attr: 'statusList',
		// 	type: 'multi-select',
		// 	options: status,
		// },
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'belongCompanyIds',
			type: 'multi-select',
			width: 250,
			options: [],
		},
		{
			placeholder: '请选择平台渠道',
			label: '平台渠道',
			attr: 'platformChannelId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择库存地',
			label: '库存地',
			attr: 'stockAreaId',
			type: 'select',
			isCustom: true,
			options: [],
		},
		{
			placeholder: '请选择资产所属公司',
			label: '资产所属公司',
			attr: 'assetBelongCompanyIds',
			type: 'multi-select',
			width: 250,
			options: [],
		},
		{
			placeholder: '请选择车系',
			label: '车系名称',
			attr: 'carSeriesIds',
			type: 'multi-select',
			options: [],
		},
		{
			placeholder: '请选择车辆类型',
			label: '车辆类型',
			attr: 'fuelTypes',
			type: 'multi-select',
			options: [],
		},

		{
			placeholder: '请选择车辆证件',
			label: '车辆证件',
			attr: 'carInfoQueryFile',
			type: 'select',
			options: [],
		},
		{ label: '创建时间', attr: 'createTime', type: 'date' },
		{
			placeholder: '请选择年检状态',
			label: '年检状态',
			attr: 'annualAuditStatusList',
			type: 'select',
		},
		{
			placeholder: '请选择交强险状态',
			label: '交强险状态',
			attr: 'compulsoryInsuranceStatusList',
			type: 'select',
		},
		{
			placeholder: '请选择商业险状态',
			label: '商业险状态',
			attr: 'commercialInsuranceStatusList',
			type: 'select',
		},
		{
			placeholder: '请选择是否在库',
			label: '是否在库',
			attr: 'isInStock',
			type: 'select',
			options: [
				{ name: '是', code: true },
				{ name: '否', code: false },
			],
		},
	],
}
