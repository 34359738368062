export const form = [
	{
		prop: 'belongCompanyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		options: [],
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'carNum',
		label: '自编号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入自编号' },
	},
	{
		prop: 'carModelId',
		label: '车辆车型',
		required: true,
		custom: true,
		component: 'el-select',
		attrs: { placeholder: '请输入车辆车型', remote: true, filterable: true },
	},
	{
		prop: 'carColor',
		label: '车辆颜色',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择车辆颜色' },
	},
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		required: true,
		component: 'el-input',
		custom: true,
		attrs: { placeholder: '请输入车牌号', maxLength: 8 },
	},
	{
		prop: 'engineNum',
		label: '发动机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入发动机号', maxLength: 20 },
	},
	{
		prop: 'vin',
		label: '车架号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入车架号', maxLength: 20 },
	},
	{
		prop: 'gpsNum',
		label: 'GPS设备号',
		component: 'el-input',
		attrs: { placeholder: '请输入GPS设备号', maxLength: 20 },
	},
	{
		prop: 'standbyGpsNum',
		label: '备用GPS',
		component: 'el-input',
		attrs: { placeholder: '请输入备用GPS号', maxLength: 20 },
	},
	{
		prop: 'stockAreaId',
		label: '库存地',
		required: true,
		component: 'el-select',
		options: [],
		attrs: { placeholder: '请选择库存地' },
	},
	{
		prop: 'platformChannelIds',
		label: '平台渠道',
		component: 'el-select',
		options: [],
		attrs: { placeholder: '请选择平台渠道', multiple: true },
	},
	{
		prop: 'maintainerId',
		label: '维保专员',
		component: 'el-select',
		options: [],
		attrs: { placeholder: '请选择维保专员' },
	},
	{
		prop: 'assetBelongCompanyId',
		label: '资产所属公司',
		component: 'el-select',
		required: true,
		options: [],
		attrs: { placeholder: '请选择资产所属公司' },
	},
	{
		prop: 'registerDate',
		label: '车辆注册时间',
		component: 'el-date-picker',
		required: true,
		custom: true,
	},
	{
		prop: 'annualAuditDate',
		label: '年检日期',
		required: true,
		component: 'el-date-picker',
		custom: true,
		attrs: {
			type: 'daterange',
			message: '请选择年检日期',
			'start-placeholder': '请选择开始日期',
			'end-placeholder': '请选择结束日期',
		},
	},
	{
		prop: 'clivtaInsuranceCompanyId',
		label: '交强险保险公司',
		required: true,
		component: 'el-select',
		options: [],
		attrs: { placeholder: '请选择交强险保险公司' },
	},
	{
		prop: 'clivtaDate',
		label: '交强险日期',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择交强险日期' },
	},
	{
		prop: 'carInsuranceCompanyId',
		label: '商业险保险公司',

		component: 'el-select',
		options: [],
		attrs: { placeholder: '请选择商业险保险公司' },
	},
	{
		prop: 'carInsuranceDate',
		label: '商业险日期',
		attrs: {},
		custom: true,
	},
	{
		prop: 'remark',
		label: '备注',
		component: 'el-input',
		cWidth: 500,
		attrs: {
			type: 'textarea',
			placeholder: '请输入备注',
			autosize: { minRows: 4 },
			maxLength: 200,
		},
	},
]

export const uploadForm = [
	{
		prop: 'drivingLicenseList',
		label: '车辆行驶证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传车辆行驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'optCertificateList',
		label: '车辆营运证',
		cWidth: 500,
		component: 'yi-file-uploader',
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传车辆营运证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'mvrcList',
		label: '机动车登记证',
		cWidth: 500,
		component: 'yi-file-uploader',
		attrs: {
			accept: '.pdf,.jpg,.jpeg,.png,.bmp',
			max: 5,
			type: 'file',
			message: '请上传机动车登记证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'vehicleCertificateList',
		label: '车辆合格证',
		cWidth: 500,
		component: 'yi-file-uploader',
		attrs: {
			accept: '.pdf,.jpg,.jpeg,.png,.bmp',
			max: 5,
			type: 'file',
			message: '请上传车辆合格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'otherCertificateList',
		label: '其它证件',
		cWidth: 500,
		component: 'yi-file-uploader',
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传其它证件',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
