<template>
	<div class="container">
		<label-item title="合同档案">
			<template #subTitle>
				<span class="click" @click="onJump">查看全部</span>
			</template>
		</label-item>
		<yi-table :loading="isLoading" table-height="100%" :columns="columns" :data="tableData">
			<template #status="{ value }">
				<span>{{ mapDict('order_status', value) }}</span>
			</template>
			<template #driverName="{ value }">
				<span class="click" @click="onToDriver(value)">{{ value }}</span>
			</template>
		</yi-table>
	</div>
</template>

<script setup>
import { defineExpose, defineProps, inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { getDetailByCar } from '@/api/vehicle'
import labelItem from './label'

const _ = defineProps({
	data: {
		type: Object,
		default: () => ({}),
	},
})

const $message = inject('$message')
const router = useRouter()
const columns = [
	{
		prop: 'status',
		label: '合同状态',
		width: '100px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '100px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '100px',
	},
	{
		prop: 'contractPeriod',
		label: '期数',
		width: '100px',
	},
	{
		prop: 'monthlyRent',
		label: '月租',
		width: '100px',
	},
	{
		prop: 'securityDeposit',
		label: '押金',
		width: '100px',
	},
	{
		prop: 'contractStartDate',
		label: '交付日期',
	},
	{
		prop: 'contractEndDate',
		label: '到期日期',
	},
]
const [dict, setDict] = useState({})
const { isLoading, fetchData } = useFetch()
const [tableData, setTable] = useState([])
async function fetchDict() {
	const dict = await useDict('order_status')
	if (dict) setDict(dict)
}
async function fetchList() {
	const { err, data } = await fetchData(getDetailByCar, { carId: _.data.id })
	if (err) return $message.error(err.message)
	setTable(data)
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)
	return target ? target.name : '--'
}
function onJump() {
	router.push({ path: '/driver-contract', query: { keyword: _.data.licensePlateNum } })
}
function onToDriver(keyword) {
	router.push({ path: '/driver-management', query: { keyword } })
}
function init() {
	fetchDict()
	fetchList()
}
onMounted(() => {
	init()
})
defineExpose({ init })
</script>

<style lang="less" scoped>
.container {
	.click {
		font-size: 14px;
		color: #2d8cf0;
		cursor: pointer;
		margin-left: 12px;
	}
}
</style>
