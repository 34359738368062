<template>
	<el-dialog
		v-model="visible"
		width="600px"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				已选中
				<span style="color: #d9001b">{{ amount }}</span>
				辆车，车辆明细如下：
			</p>
			<p v-if="licensePlateNum">
				{{ licensePlateNum }}
			</p>
			<p style="font-weight: 600">确定批量更新以上车辆的年检时间</p>
			<p style="font-weight: 600">提示：如以上车辆年检未到期，不会新增年检记录</p>
			<!-- <el-form :model="formData" :rules="rules" ref="formRef">
				<el-form-item label="本次年检日期" prop="annualAuditDateStart">
					<el-date-picker
						v-model="formData.annualAuditDateStart"
						@update:modelValue="onDateChange"
						placeholder="请选择本次年检日期"
					/>
				</el-form-item>
				<el-form-item label="下次年检日期" prop="annualAuditDateEnd">
					<el-date-picker v-model="formData.annualAuditDateEnd" placeholder="请选择下次年检日期" />
				</el-form-item>
			</el-form> -->
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { batchCheck } from '@/api/vehicle.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, title, setVisible, setTitle } = useModal()
const [amount, setAmount] = useState(0)
const [selected, setSelected] = useState([])
const [licensePlateNum, setLicensePlateNum] = useState('')
const formRef = ref(null)
const { isLoading, fetchData } = useFetch()
const [formData, _] = useState({
	annualAuditDateStart: new Date(),
	annualAuditDateEnd: dayjs().add(1, 'y').format('YYYY-MM-DD HH:mm:ss'),
})
// const rules = {
// 	annualAuditDateStart: [{ required: true, message: '本次年检日期不能为空' }],
// 	annualAuditDateEnd: [{ required: true, message: '下次年检日期不能为空' }],
// }

function open(data = []) {
	setTitle('批量年检')
	setAmount(data.length)
	setVisible(true)
	setSelected(data)
	const num = data.map(item => item.licensePlateNum).join('、')
	setLicensePlateNum(num)
}

// function onDateChange(val) {
// 	formData.value.annualAuditDateEnd = dayjs(val).add(1, 'y').format('YYYY-MM-DD HH:mm:ss')
// }

function onClose() {
	setVisible(false)
}
async function onSubmit() {
	// await formRef.value.validate()
	// const { annualAuditDateStart: start, annualAuditDateEnd: end } = formData.value
	const params = {
		// annualAuditDateStart: dayjs(start).format('YYYY-MM-DD HH:mm:ss'),
		// annualAuditDateEnd: dayjs(end).format('YYYY-MM-DD HH:mm:ss'),
		carIds: selected.value.map(item => item.id),
	}
	const { err } = await fetchData(batchCheck, params)
	if (err) return $message.error(err.message)
	$message.success('修改车辆年检成功')
	emit('on-refresh')
	onClose(false)
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
</style>
