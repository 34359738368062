<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				已选中
				<span style="color: #d9001b">{{ count }}</span>
				辆车，车辆明细如下：
			</p>
			<div v-if="licensePlateNum" class="bold">
				<p>{{ licensePlateNum }}</p>
			</div>
			<p class="bold">确定批量更新以上车辆的维保专员</p>
		</div>
		<el-form :model="formData" :rules="rules" ref="formRef">
			<el-form-item label="维保专员" prop="maintainerId">
				<el-select v-model="formData.maintainerId" placeholder="请选择维保专员">
					<el-option
						v-for="item in maintainer"
						:key="item.id"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { getStore } from '@/utils/store'
import { batchSpecialist, maintenanceSelect } from '@/api/vehicle.js'

const $message = inject('$message')
const userInfo = getStore({ name: 'userInfo' })
const emit = defineEmits(['on-refresh'])
const { visible, title, setVisible, setTitle } = useModal()
const [count, setCount] = useState(0)
const [selected, setSelected] = useState([])
const [maintainer, setMaintainer] = useState([])
const [licensePlateNum, setLicensePlateNum] = useState('')
const formRef = ref(null)
const { isLoading, fetchData } = useFetch()
const [formData, setForm] = useState({
	maintainerId: '',
})
const rules = {
	maintainerId: [{ required: true, message: '维保专员不能为空' }],
}

function open(data = [], isDetail = false) {
	const params = isDetail ? data : ''
	fetchList(params)

	setTitle('修改维保专员')
	setCount(data.length)

	setSelected(data)
	const num = data.map(item => item.licensePlateNum).join('、')
	setLicensePlateNum(num)
	setVisible(true)
}

function onClose() {
	setVisible(false)
	setForm({
		maintainerId: '',
	})
}
async function onSubmit() {
	await formRef.value.validate()
	const params = {
		...formData.value,
		carIds: selected.value.map(item => item.id),
	}

	const { err } = await fetchData(batchSpecialist, params)
	if (err) return $message.error(err.message)
	$message.success('修改维保专员成功')
	emit('on-refresh')
	onClose()
}
async function fetchList(params) {
	const { companyId } = userInfo
	const arr = params[0] ? [params[0].belongCompanyId] : companyId ? companyId.split(',') : []
	const { err, data } = await fetchData(maintenanceSelect, 'Mainten', arr, { isAll: true })
	if (err) $message.error(err.message)
	setMaintainer(data.map(item => ({ label: item.realName, value: item.id })))
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.bold {
	font-weight: 600;
}
</style>
