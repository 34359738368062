<template>
	<div class="state-flow">
		<div
			v-for="(item, index) in stateList"
			:key="index"
			class="state-item"
			:class="{ disabled: item.nodeStatus == 3 }"
		>
			<el-popover placement="top-start" :width="300" trigger="click" :content="item.desc">
				<template #reference>
					<div class="status" :class="{ active: item.nodeStatus == 2 }">
						{{ item.statusName }}
					</div>
				</template>
				<div v-for="(val, i) in item.descList" :key="i">{{ val }}</div>
			</el-popover>
			<div
				v-if="index !== stateList.length - 1"
				class="line"
				:class="{ disabled: [2, 3].includes(item.nodeStatus) }"
			>
				<span class="trangle"></span>
			</div>
		</div>
	</div>
</template>
<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
	stateList: {
		type: Array,
		default: () => [],
	},
})
const curStateIndex = computed(() => {
	let index = props.stateList.findIndex(item => item.isCurrent)
	return index
})
</script>
<style lang="less" scope>
@import '../../styles/mixins.less';

.state-flow {
	height: 60px;
	.flexible(row, center, flex-start);
	.state-item {
		display: flex;
		align-items: center;
		height: 60px;
		.status {
			width: 45px;
			height: 45px;
			line-height: 45px;
			border-radius: 50%;
			background: #409eff;
			font-size: 12px;
			color: #fff;
			text-align: center;
			cursor: pointer;
			&.active {
				background: #fff;
				border: 2px solid #409eff;
				color: #409eff;
				font-weight: 500;
			}
		}
		.line {
			position: relative;
			width: 35px;
			height: 2px;
			margin: 0 6px 0 5px;
			background: #409eff;
			&.disabled {
				background: #ddd;
				.trangle {
					border-color: transparent transparent transparent #ddd;
				}
			}
			.trangle {
				position: absolute;
				right: -6px;
				top: -4.5px;
				width: 0;
				height: 0;
				border: 5px solid;
				border-color: transparent transparent transparent #409eff;
			}
		}
		&.disabled {
			.status {
				background: #ddd;
				color: #999;
			}
			.line {
				background: #ddd;
				.trangle {
					border-color: transparent transparent transparent #ddd;
				}
			}
		}
	}
}
</style>
