<template>
	<el-drawer
		class="container"
		v-model="visible"
		:append-to-body="false"
		:show-close="false"
		size="1100"
		:with-header="false"
	>
		<div v-loading="isLoading" class="detail-container">
			<div class="header">
				<div>
					<p class="title">
						车牌号：{{ detail.licensePlateNum }} （{{ mapStatus(detail.status) }}）
					</p>
					<div class="top-container-main">
						<span class="label">
							所属公司：
							<span class="value">{{ detail.belongCompanyName }}</span>
						</span>
						<span class="label">
							创建时间：
							<span class="value">{{ detail.createTime }}</span>
						</span>
						<span class="label">
							最后更新时间：
							<span class="value">{{ detail.updateTime }}</span>
						</span>
					</div>
				</div>
				<el-icon size="28" color="#707070" style="cursor: pointer" @click="onClose">
					<CircleClose />
				</el-icon>
			</div>

			<div class="main-container">
				<div class="left-container">
					<div class="button-list">
						<el-button class="button" @click="onEdit">编辑</el-button>
						<el-button class="button" @click="onMaintain">修改维保员</el-button>
						<el-button class="button" @click="onInspection">车辆年检</el-button>
						<el-button class="button" @click="onRenew">车辆续保</el-button>
					</div>
					<div class="left-label">司机姓名</div>
					<div class="staff">
						<div class="avatar" v-if="detail.driverName">
							<p>
								{{ formatName(detail.driverName) }}
							</p>
						</div>
						<p>{{ detail.driverName }}</p>
						<p class="staff-phone">{{ detail.driverPhone }}</p>
					</div>
					<div class="left-label">维保专员</div>
					<div class="staff">
						<div class="avatar" v-if="detail.maintainerName">
							<p>
								{{ formatName(detail.maintainerName) }}
							</p>
						</div>
						<p>{{ detail.maintainerName }}</p>
						<p class="staff-phone">{{ detail.maintainerPhone }}</p>
					</div>
					<div class="left-label">司服伙伴</div>
					<div class="staff">
						<div class="avatar" v-if="detail.driverSupportName">
							<p>
								{{ formatName(detail.driverSupportName) }}
							</p>
						</div>
						<p>{{ detail.driverSupportName }}</p>
						<p class="staff-phone">{{ detail.driverSupportPhone }}</p>
					</div>
				</div>
				<div class="tab-container">
					<StateFlow :stateList="stateList" />
					<el-tabs v-model="activeTab" class="tab-container-self" @tab-change="handleTabClick">
						<el-tab-pane label="车辆信息" name="vehicle">
							<VehicleTab :data="detail" v-if="activeTab === 'vehicle'" />
						</el-tab-pane>
						<el-tab-pane label="合同信息" name="contract">
							<ContractTab :data="detail" v-if="activeTab === 'contract'" />
						</el-tab-pane>
						<el-tab-pane label="维保信息" name="maintenance">
							<MaintenanceTab :data="detail" v-if="activeTab === 'maintenance'" />
						</el-tab-pane>
						<el-tab-pane :label="`违章信息(${detail.carViolateNum || 0})`" name="violation">
							<ViolationTab :data="detail" v-if="activeTab === 'violation'" />
						</el-tab-pane>
						<el-tab-pane label="操作记录" name="operation">
							<OperationTab ref="operationRef" :data="detail" v-if="activeTab === 'operation'" />
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<management-form ref="formRef" @on-refresh="refreshDetail" />
			<inspection-form ref="inspectionRef" @on-refresh="refreshDetail" />
			<edit-specialist ref="specialistRef" @on-refresh="refreshDetail" />
			<batch-renewal ref="renewalRef" @on-refresh="refreshDetail" />
		</div>
	</el-drawer>
</template>

<script setup>
import { defineExpose, inject, ref } from 'vue'
import { useState, useFetch } from '@/utils/hooks.js'
import { vehicleDetail } from '@/api/vehicle'
import Request from '@/http'
import { status } from '../../enums'
import StateFlow from '@/components/StateFlow'
import VehicleTab from './vehicleTab'
import ContractTab from './contractTab'
import MaintenanceTab from './maintenanceTab'
import ViolationTab from './violationTab.vue'
import OperationTab from './operationTab.vue'

import ManagementForm from '../components/form'
import InspectionForm from '../inspection'
import EditSpecialist from '../editSpecialist'
import BatchRenewal from '../renewal'

const $message = inject('$message')

const [visible, setVisible] = useState(false)
const [activeTab, setTab] = useState('vehicle')
const [detail, setDetailData] = useState({})
const [detailId, setId] = useState('')
const [stateList, setStateList] = useState([])
const { isLoading, fetchData } = useFetch()

const formRef = ref(null)
const inspectionRef = ref(null)
const specialistRef = ref(null)
const renewalRef = ref(null)
const operationRef = ref(null)

function onEdit() {
	formRef.value.open(detail.value)
}
function onClose() {
	setVisible(false)
	setDetailData({})
	setId('')
}
function onMaintain() {
	specialistRef.value.open([detail.value], 'detail')
}
function onInspection() {
	inspectionRef.value.open([detail.value])
}
function onRenew() {
	renewalRef.value.open([detail.value], 'detail')
}

function formatName(name) {
	return name.substring(name.length - 2, name.length)
}
async function fetchDetail(id) {
	const { data, err } = await fetchData(vehicleDetail, id)
	if (err) return $message.error(err.message)
	setDetailData(data)
	setId(data.id)
}
function refreshDetail() {
	fetchDetail(detailId.value)
	operationRef.value && operationRef.value.init()
}
function mapStatus(value) {
	const target = status.find(item => item.code == value)
	if (!target) return '--'
	return target.name
}
function handleTabClick(val) {
	setTab(val)
	// switch (val) {
	// 	case 'vehicle':
	// 		break
	// 	case 'contract':
	// 		contractRef.value && contractRef.value.init()
	// 		break
	// 	case 'maintenance':
	// 		maintenanceRef.value && maintenanceRef.value.init()
	// 		break
	// 	case 'violation':
	// 		violationRef.value && violationRef.value.init()
	// 		break
	// 	case 'operation':
	// 		operationRef.value && operationRef.value.init()
	// 		break
	// 	default:
	// 		break
	// }
}
async function fetchStateFlow(id) {
	Request({
		url: `/suf4-asset-service/pc/assetCarInfo/listAssetCarInfoStatusFlow?id=${id}`,
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				setStateList(
					(res.data || []).map(item => {
						return {
							...item,
							descList: [`${item.desc || '无'}`],
						}
					}),
				)
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			console.log('getCarInfoStatusFlow失败 ==>', error)
			return false
		})
}
function open(id) {
	fetchDetail(id)
	fetchStateFlow(id)
	setTab('vehicle')
	setVisible(true)
}
defineExpose({ open })
</script>
<style lang="less">
.container > .el-drawer__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.detail-container {
	width: 100%;
	height: 100%;
	.flexible(column, flex-start, flex-start);
	overflow: hidden;
}
.header {
	padding: 5px 0 10px 24px;
	background-color: #fff;
	.flexible(row,center, space-between);
	width: 1050px;
	.title {
		font-size: 18px;
		margin-bottom: 8px;
	}
	.extraInfo {
		color: #cfcfcf;
	}
	.top-container-main {
		display: flex;
		align-items: center;
		padding-right: 24px;
		.top-container-main-right {
			.button {
				margin-right: 10px;
			}
		}
		.label {
			font-size: 12px;
			color: #707070;
			margin-right: 10px;
		}
		.value {
			font-size: 12px;
			color: rgba(0, 0, 0.84);
			&.click-tip {
				color: #409eff;
			}
		}
	}
}
.main-container {
	flex: 1;
	.flexible(row, flex-start, flex-start);
	border-top: 7px solid #f0f2f5;
	width: 100%;
	height: 0;
	.left-container {
		width: 338px;
		height: 100%;
		padding: 16px;
		overflow-y: auto;
		.button-list {
			.flexible(row, center, flex-start);
			flex-wrap: wrap;
			.button {
				width: 120px;
				height: 36px;
				border: 1px solid #dcdfe6;
				border-radius: 4px;
				.flexible(row, center, center);
				color: #606266;
				margin: 0 20px 20px 20px;
			}
		}
		.left-label {
			border-left: 3px solid #2e3443;
			padding-left: 4px;
			line-height: 15px;
			font-size: 16px;
			margin: 10px 0;
		}
		.staff {
			.flexible(row, center, flex-start);
			margin-right: 20px;
			.staff-phone {
				margin-left: 20px;
				font-size: 14px;
			}
		}
		.avatar {
			height: 30px;
			width: 30px;
			border-radius: 15px;
			margin-right: 20px;
			.flexible(row, center, center);
			background-color: #409eff;
			color: white;
			text-align: center;
			font-size: 14px;
		}
	}
	.tab-container {
		border-left: 7px solid #f0f2f5;
		padding: 8px 20px;
		flex: 1;
		height: 100%;
		width: 0;
		overflow: auto;
	}
	.tab-container::-webkit-scrollbar {
		width: 0 !important;
	}
}
</style>
