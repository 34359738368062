<template>
	<div class="container" v-loading="isLoading">
		<label-item title="维保记录">
			<template #subTitle>
				<span class="click" @click="onViewMaintenance">查看全部</span>
			</template>
		</label-item>
		<yi-table table-height="100%" :columns="columns" :data="maintainData"></yi-table>
		<label-item title="事故记录">
			<template #subTitle>
				<span class="click" @click="onViewIncidental">查看全部</span>
			</template>
		</label-item>
		<yi-table table-height="100%" :columns="incidentalColumns" :data="incidentalData">
			<template #sitePics="{ value }">
				<span class="click" @click="onViewPic(value)">查看</span>
			</template>
			<template #letterOfResponsibilityPics="{ value }">
				<span class="click" @click="onViewPic(value)">查看</span>
			</template>
			<template #liabilityType="{ value }">
				<span>{{ mapDict('liabilityType', value) }}</span>
			</template>
		</yi-table>
		<el-image-viewer
			v-if="visible"
			:url-list="images"
			hide-on-click-modal
			@close="setVisible(false)"
		/>
	</div>
</template>

<script setup>
import { defineProps, defineExpose, inject, onMounted } from 'vue'

import { useRouter } from 'vue-router'
import labelItem from './label'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { maintenanceList, incidentalList } from '@/api/vehicle'

const _ = defineProps({
	data: Object,
})
const $message = inject('$message')
const [visible, setVisible] = useState(false)
const [maintainData, setMaintain] = useState([])
const [dict, setDict] = useState({})
const [images, setImages] = useState([])
const [incidentalData, setIncidental] = useState([])
const { isLoading, fetchData } = useFetch()
const router = useRouter()
const columns = [
	{
		prop: 'projectTypeStr',
		label: '维修项目',
	},
	{
		prop: 'repairCompanyName',
		label: '维修厂',
	},
	{
		prop: 'createTimeStr',
		label: '进厂日期',
	},
	{
		prop: 'mileage',
		label: '进厂公里数',
	},
	{
		prop: 'maintenanceEndTime',
		label: '出厂日期',
	},
	{
		prop: 'maintenanceCost',
		label: '维保费用',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
	},
]
const incidentalColumns = [
	{
		prop: 'driverName',
		label: '司机姓名',
	},
	{
		prop: 'dateOfAccident',
		label: '事故日期',
	},
	{
		prop: 'liabilityType',
		label: '事故责任',
	},
	{
		prop: 'sitePics',
		label: '现场照片',
	},
	{
		prop: 'letterOfResponsibilityPics',
		label: '定责书照片',
	},
]

function onViewMaintenance() {
	router.push({ path: '/maintenance-management', query: { keyword: _.data.licensePlateNum } })
}
function onViewIncidental() {
	router.push({ path: '/incident-management', query: { keyword: _.data.licensePlateNum } })
}
function onViewPic(value) {
	setImages(value)
	setVisible(true)
}
async function fetchMaintain() {
	const { err, data } = await fetchData(maintenanceList, {
		keyword: _.data.licensePlateNum,
	})
	if (err) $message.error(err.message)
	setMaintain(data.records)
}
async function fetchIncidental() {
	const { err, data } = await fetchData(incidentalList, {
		keyword: _.data.licensePlateNum,
	})
	if (err) $message.error(err.message)
	setIncidental(data.records)
}
async function fetchDict() {
	const dict = await useDict('liabilityType')
	if (dict) setDict(dict)
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)
	return target ? target.name : '--'
}
function init() {
	fetchDict()
	fetchMaintain()
	fetchIncidental()
}
onMounted(() => {
	init()
})
defineExpose({ init })
</script>

<style lang="less" scoped>
.container {
	.click {
		font-size: 14px;
		color: #2d8cf0;
		cursor: pointer;
		margin-left: 12px;
	}
	.viewer {
		display: none;
	}
}
</style>
