<template>
	<div class="container">
		<div class="title">
			<span>{{ props.title }}</span>
			<slot name="subTitle"></slot>
		</div>
		<div class="list">
			<div class="item" v-for="(item, i) in props.list" :key="i">
				<p class="label">{{ item.label }}：</p>
				<p class="value">{{ convert(item.value) }}</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
	title: String,
	list: Array,
})
const convert = val => {
	if (![null, undefined, ''].includes(val)) return val
	return '--'
}
</script>
<style lang="less" scoped>
.container {
	background: #fff;
	.title {
		border-left: 3px solid #2e3443;
		padding-left: 4px;
		line-height: 15px;
		font-size: 16px;
		margin: 10px 0 10px 0;
	}
	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.item {
			.flexible(row, center, flex-start);
			width: 50%;
			height: 30px;
			font-size: 14px;
			.label {
				color: #707070;
				max-width: 200px;
			}
			.value {
				color: #292929;
				flex: 1;
				// width: 220px;
				max-width: 250px;
			}
		}
	}
}
</style>
