<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-if="visible" v-loading="isLoading">
			<yi-form
				v-if="visible"
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #carModelId>
					<el-select
						size="default"
						style="width: 500px"
						placeholder="请输入车辆车型"
						@change="onModelChange"
						remote
						v-model="model.carModelId"
						:remote-method="onModelInput"
						filterable
					>
						<el-option
							v-for="item in options"
							:key="item.id"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</template>
				<template #licensePlateNum>
					<el-input
						size="default"
						maxLength="8"
						placeholder="请输入车牌号"
						v-model="model.licensePlateNum"
						@input="onNumChange"
						style="width: 190px"
					/>
				</template>
				<template #registerDate>
					<el-date-picker
						placeholder="请选择车辆注册时间"
						type="date"
						size="default"
						style="width: 192px"
						v-model="model.registerDate"
					/>
				</template>
				<template #annualAuditDate>
					<el-date-picker
						:disabled="disabled"
						@calendar-change="onDateChange('annualAuditDate', $event)"
						start-placeholder="请选择开始日期"
						end-placeholder="请选择结束日期"
						type="daterange"
						size="default"
						style="width: 480px"
						v-model="model.annualAuditDate"
					/>
				</template>
				<template #carInsuranceDate>
					<el-date-picker
						:disabled="disabled"
						@calendar-change="onDateChange('carInsuranceDate', $event)"
						type="daterange"
						start-placeholder="请选择开始日期"
						end-placeholder="请选择结束日期"
						style="width: 480px"
						size="default"
						v-model="model.carInsuranceDate"
					/>
				</template>
				<template #clivtaDate>
					<el-date-picker
						:disabled="disabled"
						@calendar-change="onDateChange('clivtaDate', $event)"
						type="daterange"
						size="default"
						start-placeholder="请选择开始日期"
						end-placeholder="请选择结束日期"
						style="width: 480px"
						v-model="model.clivtaDate"
					/>
				</template>
			</yi-form>
			<p class="title">车辆证件</p>
			<yi-form
				:model="upload"
				:rules="rules"
				ref="uploadRef"
				label-width="120px"
				size="small"
				:form-list="uploadForm"
			/>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, ref, inject, defineEmits, watch } from 'vue'
import { useState, useModal, useFetch, useSelect } from '@/utils/hooks.js'
import { numAndAlphaReg } from '@/utils/regUtil.js'
import {
	createVehicle,
	maintenanceSelect,
	insuranceCompanySelect,
	companySelect,
	channelSelect,
	storageSelect,
	searchModel,
	editVehicle,
	vehicleDetail,
} from '@/api/vehicle'
import { form, uploadForm } from './config'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const formList = ref(form)

const { visible = true, title, setVisible, setTitle } = useModal()

const [model, setModel] = useState({})
const [upload, setUpload] = useState({})
const [car, setCar] = useState({})
const [disabled, setDisabled] = useState(false)
const [options, setOptions] = useState({})
const { isLoading, fetchData } = useFetch()

const companyValidator = (_, val, callback) => {
	const { carInsuranceDate } = model.value
	if (carInsuranceDate && carInsuranceDate[0] && !val) {
		callback(new Error('请选择商业险保险公司'))
	} else {
		callback()
	}
}
const dateValidator = (_, val, callback) => {
	const { carInsuranceCompanyId } = model.value
	if (carInsuranceCompanyId && !val) {
		callback(new Error('请选择商业险日期'))
	} else {
		callback()
	}
}
let isInit = false
const rules = {
	engineNum: [{ pattern: numAndAlphaReg, message: '发动机号只能是数字和字母' }],
	vin: [{ pattern: numAndAlphaReg, message: '车架号只能是数字和字母' }],
	gpsNum: [{ pattern: numAndAlphaReg, message: 'GPS设备号只能是数字和字母' }],
	standbyGpsNum: [{ pattern: numAndAlphaReg, message: '备用GPS只能是数字和字母' }],
	carInsuranceCompanyId: [
		{ validator: companyValidator, message: '请选择商业险保险公司', trigger: 'blur' },
	],
	registerDate: [{ required: true, message: '请选择车辆注册时间' }],
	carInsuranceDate: [{ validator: dateValidator, message: '请选择商业险日期', trigger: 'blur' }],
}
const formRef = ref(null)
const uploadRef = ref(null)

watch(
	() => model.value.belongCompanyId,
	(val, oldVal) => {
		console.log('watch belongCompanyId', val, oldVal)
		fetchChannel(val)
		fetchStorage(val)
		fetchInsuranceCompany(val)
		fetchMaintenance(val)
		if (oldVal != val) {
			if (!isInit) {
				model.value.platformChannelIds = []
				model.value.stockAreaId = ''
				model.value.clivtaInsuranceCompanyId = ''
				model.value.maintainerId = ''
				model.value.carInsuranceCompanyId = ''
			} else {
				isInit = false
			}
		}
	},
)

async function onModelInput(key = '') {
	const res = await searchModel({ key })
	if (res.code === 200) {
		setCar(res.data || {})
		const data = res.data.map(item => ({ label: item.aggregationColumn, value: item.id }))
		setOptions(data)
	}
}

function onModelChange(key) {
	const target = options.value.find(car => car.value === key)
	setModel({ ...model.value, carModelName: target ? target.label : '', carColor: '' })
	const carColor = formList.value.find(item => item.prop === 'carColor')
	const val = target ? target.value : ''
	const curCar = car.value.find(item => item.id === val)
	if (curCar && curCar.outerColors) {
		carColor.options = curCar.outerColors.split(',').map(color => ({ label: color, value: color }))
	}
}

function open(data) {
	fetchCompany()
	onModelInput()
	if (data) {
		setTitle('编辑车辆')
		fetchDetail(data.id)
		isInit = true
	} else {
		setTitle('新增车辆')
	}
	;[
		'carNum',
		'licensePlateNum',
		'engineNum',
		'vin',
		'annualAuditDate',
		'clivtaInsuranceCompanyId',
		'carInsuranceCompanyId',
	].forEach(key => {
		const target = formList.value.find(item => item.prop === key)
		target.attrs.disabled = !!data
	})
	setDisabled(!!data)
	const searchModel = formList.value.find(item => item.prop === 'carModelId')
	searchModel.on = { change: onModelChange }
	searchModel.attrs = { ...searchModel.attrs, remoteMethod: onModelInput }
	setVisible(true)
}

async function fetchDetail(id) {
	const { err, data } = await fetchData(vehicleDetail, id)
	if (err) return $message.error(err.msg || err.message)
	const { carModelId } = data
	onModelChange(carModelId)
	// 映射日期选择
	const { annualAuditDateStart, annualAuditDateEnd } = data
	const { clivtaDateStart, clivtaDateEnd } = data
	const { carInsuranceDateStart, carInsuranceDateEnd } = data
	setModel({
		...data,
		annualAuditDate: [annualAuditDateStart, annualAuditDateEnd],
		clivtaDate: [clivtaDateStart, clivtaDateEnd],
		carInsuranceDate: [carInsuranceDateStart, carInsuranceDateEnd],
	})
	const {
		drivingLicenseList,
		optCertificateList,
		mvrcList,
		vehicleCertificateList,
		otherCertificateList,
	} = data
	// 图片不能传null
	setUpload({
		drivingLicenseList: drivingLicenseList || [],
		optCertificateList: optCertificateList || [],
		mvrcList: mvrcList || [],
		vehicleCertificateList: vehicleCertificateList || [],
		otherCertificateList: otherCertificateList || [],
	})
}

function onClose() {
	setVisible(false)
	setModel({})
	setUpload({})
	setCar({})
}
function onDateChange(key, val) {
	const start = val[0]
	if (key === 'annualAuditDate') {
		model.value[key] = [start, dayjs(start).add(1, 'y').endOf('month').$d]
	} else {
		model.value[key] = [start, dayjs(start).add(1, 'y').subtract(1, 'day').$d]
	}
}
function onNumChange(val) {
	if (val) {
		model.value.licensePlateNum = val.toUpperCase().replace(/\s+/g, '')
	}
}
async function onSubmit() {
	await Promise.all([formRef.value.validate(), uploadRef.value.validate()])
	try {
		const params = { ...model.value, ...upload.value }
		;['annualAuditDate', 'clivtaDate', 'carInsuranceDate'].forEach(key => {
			if (model.value[key]) {
				const [start, end] = model.value[key]
				if (start) params[`${key}Start`] = dayjs(start).format('YYYY-MM-DD') + ' 00:00:00'
				if (end) params[`${key}End`] = dayjs(end).format('YYYY-MM-DD') + ' 23:59:59'
			}
		})
		params.registerDate = dayjs(params.registerDate).format('YYYY-MM-DD HH:mm:ss')
		console.log('submit', params)
		const service = title.value.includes('编辑') ? editVehicle : createVehicle
		const res = await service(params)
		if (res.code !== 200) throw new Error(res.msg || res.message)
		$message.success(title.value.includes('编辑') ? '编辑成功' : '新建成功')
		emit('on-refresh')
		onClose()
	} catch (e) {
		console.log('e', e)
		$message.error(e.message)
	}
}
async function fetchChannel(companyId) {
	const { err, data } = await useSelect(() => channelSelect({ companyId, status: 1 }))
	if (err) return console.log('err', err)
	const target = findItem('platformChannelIds')
	target.options = data
	if (data.length === 1) model.value.platformChannelIds = [data[0].value]
}
async function fetchStorage(companyId) {
	const { err, data } = await useSelect(() => storageSelect(20, { companyId, status: 1 }))
	if (err) return console.log('err', err)
	const target = findItem('stockAreaId')
	target.options = data
	if (data.length === 1) model.value.stockAreaId = data[0].value
}
async function fetchInsuranceCompany(companyId) {
	const { err, data } = await useSelect(() => insuranceCompanySelect({ companyId, status: 1 }))
	if (err) return console.log('err', err)
	const car = findItem('carInsuranceCompanyId')
	const clivta = findItem('clivtaInsuranceCompanyId')
	car.options = data
	clivta.options = data
	if (data.length === 1) {
		// model.value.carInsuranceCompanyId = data[0].value
		model.value.clivtaInsuranceCompanyId = data[0].value
	}
}
function findItem(key) {
	return formList.value.find(item => item.prop === key)
}
async function fetchMaintenance(companyId) {
	const { err, data } = await useSelect(
		() => maintenanceSelect('Mainten', [companyId], { isAll: false }),
		{
			code: 'id',
			name: 'realName',
		},
	)
	if (err) return console.log('err', err)
	const maintainer = findItem('maintainerId')
	maintainer.options = data
	if (data.length === 1) {
		model.value.maintainerId = data[0].value
	}
}
async function fetchCompany() {
	const { err, data } = await useSelect(() => companySelect(true, { status: 1 }), {
		code: 'companyId',
		name: 'companyName',
	})
	if (err) return console.log('err', err)
	const belongCompany = findItem('belongCompanyId')
	const assetBelongCompany = findItem('assetBelongCompanyId')
	assetBelongCompany.options = data
	belongCompany.options = data
	if (data.length === 1) {
		model.value.belongCompanyId = data[0].value
		model.value.assetBelongCompanyId = data[0].value
	}
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
</style>
