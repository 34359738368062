<template>
	<el-dialog
		v-model="visible"
		width="1000px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
		v-if="visible"
	>
		<div v-loading="isLoading" v-if="visible">
			<div>
				<p>
					已选中
					<span style="color: #d9001b">{{ amount }}</span>
					辆车，车辆明细如下：
				</p>
				<p v-if="licensePlateNum" class="bold">
					{{ licensePlateNum }}
				</p>
				<p class="bold">确定批量更新以上车辆的保险时间</p>
			</div>
			<el-form :model="formData" :rules="rules" ref="formRef">
				<div class="table-wrap">
					<div class="table-content">
						<el-row v-for="(scope, index) in formData.tableData" :key="index" class="row-item">
							<div class="column-item width_100">
								<div v-if="index === 0" class="part-item title-item">车牌号</div>
								<div class="part-item full-part">{{ scope.carNumber }}</div>
							</div>
							<div class="column-item width_100">
								<div v-if="index === 0" class="part-item title-item">保险类型</div>
								<div class="part-item">交强险</div>
								<div class="part-item">商业险</div>
							</div>
							<div class="column-item width_180">
								<div v-if="index === 0" class="part-item title-item">保险公司</div>
								<div class="part-item">
									<el-form-item
										:prop="`tableData[${index}].compulsory.insuranceCompanyId`"
										:rules="rules['compulsory.insuranceCompanyId']"
									>
										<el-select
											v-model="scope.compulsory.insuranceCompanyId"
											placeholder="请选择保险公司"
											clearable
										>
											<el-option
												v-for="item in company"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											></el-option>
										</el-select>
									</el-form-item>
								</div>
								<div class="part-item">
									<el-form-item
										:prop="`tableData[${index}].commercial.insuranceCompanyId`"
										:rules="rules['commercial.insuranceCompanyId']"
									>
										<el-select
											clearable
											v-model="scope.commercial.insuranceCompanyId"
											placeholder="请选择保险公司"
										>
											<el-option
												v-for="item in company"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											></el-option>
										</el-select>
									</el-form-item>
								</div>
							</div>
							<div class="column-item width_180">
								<div v-if="index === 0" class="part-item title-item">开始日期</div>
								<div class="part-item">
									<el-form-item
										:prop="`tableData[${index}].compulsory.dateStart`"
										:rules="rules['compulsory.dateStart']"
									>
										<el-date-picker
											style="width: 156px"
											@change="val => onCompulsoryDate(val, index)"
											type="date"
											v-model="scope.compulsory.dateStart"
											placeholder="请选择开始日期"
										/>
									</el-form-item>
								</div>
								<div class="part-item">
									<el-form-item
										:prop="`tableData[${index}].commercial.dateStart`"
										:rules="rules['commercial.dateStart']"
									>
										<el-date-picker
											style="width: 156px"
											@change="val => onCommercialDate(val, index)"
											type="date"
											v-model="scope.commercial.dateStart"
											placeholder="请选择开始日期"
										/>
									</el-form-item>
								</div>
							</div>
							<div class="column-item width_180">
								<div v-if="index === 0" class="part-item title-item">结束日期</div>
								<div class="part-item">{{ scope.compulsory.dateEnd || '--' }}</div>
								<div class="part-item">{{ scope.commercial.dateEnd || '--' }}</div>
							</div>
							<div class="column-item width_480">
								<div v-if="index === 0" class="part-item title-item" style="text-align: left">
									保单文件
								</div>
								<div class="part-item">
									<el-form-item
										:prop="`tableData[${index}].compulsory.annexesArr`"
										:rules="rules['compulsory.annexesArr']"
									>
										<yi-file-uploader
											v-model="scope.compulsory.annexesArr"
											type="file"
											source-type="image"
											product-type="renrenjia"
											accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
											:max="5"
										/>
									</el-form-item>
								</div>
								<div class="part-item">
									<el-form-item
										:prop="`tableData[${index}].commercial.annexesArr`"
										:rules="rules['commercial.annexesArr']"
									>
										<yi-file-uploader
											v-model="scope.commercial.annexesArr"
											type="file"
											source-type="image"
											product-type="renrenjia"
											accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
											:max="5"
										/>
									</el-form-item>
								</div>
							</div>
						</el-row>
					</div>
				</div>
			</el-form>
			<p>提示：未选择日期，系统不会修改保险日期</p>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { batchGetInsuranceRecord, batchRenewal, insuranceCompanySelect } from '@/api/vehicle.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const formRef = ref(null)

const [amount, setAmount] = useState(0)
const [licensePlateNum, setLicensePlateNum] = useState('')
const [selected, setSelected] = useState([])
const [company, setCompany] = useState([])

const { visible, title, setVisible, setTitle } = useModal()
const { isLoading, fetchData } = useFetch()
const [tableData, setTable] = useState([])
const [formData, setForm] = useState({
	carNumber: '',
	compulsory: {
		carInsuranceId: null,
		insuranceCompanyId: '',
		dateStart: '',
		dateEnd: '',
		annexesArr: [],
		status: '',
	},
	commercial: {
		carInsuranceId: null,
		insuranceCompanyId: '',
		dateStart: '',
		dateEnd: '',
		annexesArr: [],
		status: '',
	},
	// carInsuranceDate: '',
	// carInsuranceCompanyId: '',
	// clivtaDate: '',
	// clivtaInsuranceCompanyId: '',

	tableData: tableData.value,
})
const rules = {
	'compulsory.insuranceCompanyId': [
		{ validator: validator('compulsory.insuranceCompanyId'), trigger: 'change' },
	],
	'compulsory.dateStart': [{ validator: validator('compulsory.dateStart'), trigger: 'blur' }],
	'compulsory.annexesArr': [
		{ validator: validator('compulsory.annexesArr'), trigger: ['blur', 'change'] },
	],
	'commercial.insuranceCompanyId': [
		{ validator: validator('commercial.insuranceCompanyId'), trigger: 'change' },
	],
	'commercial.dateStart': [{ validator: validator('commercial.dateStart'), trigger: 'blur' }],
	'commercial.annexesArr': [
		{ validator: validator('commercial.annexesArr'), trigger: ['blur', 'change'] },
	],
}
function validator(type) {
	return function (rule, val, callback) {
		const { field } = rule
		const index = field.replace(/[^0-9]/gi, '')
		const target = tableData.value[index]
		const { compulsory, commercial } = target

		if (type === 'compulsory.insuranceCompanyId' && !val) {
			callback(new Error('请选择交强险公司'))
			// if (compulsory.dateStart || compulsory.dateEnd)
			// else callback()
		} else if (type === 'compulsory.dateStart' && !val) {
			if (compulsory.insuranceCompanyId) callback(new Error('请选择开始时间'))
			else callback()
		} else if (type === 'compulsory.annexesArr' && !val.length) {
			if (compulsory.insuranceCompanyId && !compulsory.carInsuranceId)
				callback(new Error('请选择保单文件'))
			if (compulsory.timeChanged) callback(new Error('请选择保单文件'))
			else callback()
		} else if (type === 'commercial.insuranceCompanyId' && !val) {
			if (commercial.dateStart || commercial.dateEnd) callback(new Error('请选择商业险公司'))
			else callback()
		} else if (type === 'commercial.dateStart' && !val) {
			if (commercial.insuranceCompanyId) callback(new Error('请选择开始时间'))
			else callback()
		} else if (type === 'commercial.annexesArr' && !val.length) {
			if (!commercial.carInsuranceId && commercial.insuranceCompanyId)
				callback(new Error('请选择保单文件'))
			if (!commercial.carInsuranceId && !commercial.insuranceCompanyId && !commercial.dateStart) {
				callback()
			}
			if (commercial.timeChanged) callback(new Error('请选择保单文件'))
			else callback()
		} else {
			callback()
		}
	}
}
function open(data = [], isDetail) {
	setTitle('批量续保操作', data)
	setAmount(data.length)
	setVisible(true)
	setSelected(data)
	const params = isDetail ? data : ''
	fetchList(params)
	fetchCarInsurance(data)
	const num = data.map(item => item.licensePlateNum).join('、')
	setLicensePlateNum(num)
}
// 修改交强险开始日期
function onCompulsoryDate(val, index) {
	formData.value.tableData[index].compulsory.timeChanged = true
	formData.value.tableData[index].compulsory.dateEnd = val
		? dayjs(val).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
		: null
	// 修改了开始日期则清空原纪录id
	if (formData.value.tableData[index].compulsory.carInsuranceId) {
		formData.value.tableData[index].compulsory.carInsuranceId = null
		formData.value.tableData[index].compulsory.annexesArr = []
	}
}
// 修改商业险开始日期
function onCommercialDate(val, index) {
	formData.value.tableData[index].commercial.timeChanged = true
	formData.value.tableData[index].commercial.dateEnd = val
		? dayjs(val).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
		: null
	// 修改了开始日期则清空原纪录id
	if (formData.value.tableData[index].commercial.carInsuranceId) {
		formData.value.tableData[index].commercial.carInsuranceId = null
		formData.value.tableData[index].commercial.annexesArr = []
	}
}
function onClose() {
	setVisible(false)
	setForm({})
}
function format(date) {
	return dayjs(date).format('YYYY-MM-DD')
}
async function onSubmit() {
	await formRef.value.validate()

	const { tableData } = formData.value
	console.log('valid', tableData)
	// if (!carInsuranceDate && !carInsuranceDate.length && !clivtaDate && !clivtaDate.length)
	// 	return $message.error('请最少选择一个保险日期')
	// if (carInsuranceDate && carInsuranceDate.length && !carInsuranceCompanyId) {
	// 	if (!(clivtaDate && clivtaInsuranceCompanyId)) {
	// 		return $message.error('商业险保险公司不得为空，请选择')
	// 	}
	// }
	// if (clivtaDate && clivtaDate.length && !clivtaInsuranceCompanyId) {
	// 	if (!(carInsuranceDate.length && clivtaDate.length)) {
	// 		return $message.error('交强险保险公司不得为空，请选择')
	// 	}
	// }
	const invalid = tableData.some(item => {
		const { carInsuranceDateStart, carInsuranceDateEnd, clivtaDateStart, clivtaDateEnd } = item
		return !carInsuranceDateStart && !carInsuranceDateEnd && !clivtaDateStart && !clivtaDateEnd
	})
	console.log('invalid', invalid)
	// const params = tableData.map((item, index) => {
	// 	const res = {}
	// 	const {
	// 		carInsuranceCompanyId,
	// 		clivtaInsuranceCompanyId,
	// 		carInsuranceDateStart,
	// 		carInsuranceDateEnd,
	// 		clivtaDateStart,
	// 		clivtaDateEnd,
	// 	} = item
	// 	if (carInsuranceDateStart)
	// 		res.carInsuranceDateStart = format(carInsuranceDateStart) + ' 00:00:00'
	// 	if (carInsuranceDateEnd) res.carInsuranceDateEnd = format(carInsuranceDateEnd) + ' 23:59:59'
	// 	if (clivtaDateStart) res.clivtaDateStart = format(clivtaDateStart) + ' 00:00:00'
	// 	if (clivtaDateEnd) res.clivtaDateEnd = format(clivtaDateEnd) + ' 23:59:59'
	// 	if (carInsuranceCompanyId) res.carInsuranceCompanyId = carInsuranceCompanyId
	// 	if (clivtaInsuranceCompanyId) res.clivtaInsuranceCompanyId = clivtaInsuranceCompanyId
	// 	res.carId = selected.value[index].id
	// 	return res
	// })
	const { err } = await fetchData(batchRenewal, tableData)
	if (err) return $message.error(err.message)
	$message.success('修改续保成功')
	emit('on-refresh')
	onClose()
}

async function fetchList(val) {
	const params = val ? { companyId: val[0].belongCompanyId, status: 1 } : {}
	const { err, data } = await fetchData(() => insuranceCompanySelect(params))

	if (err) $message.error(err.message)
	setCompany(data.map(item => ({ label: item.name, value: item.id })))
}

async function fetchCarInsurance(selecteds) {
	const params = selecteds.map(item => item.licensePlateNum)
	const { err, data } = await fetchData(() => batchGetInsuranceRecord(params))

	if (err) $message.error(err.message)
	const tableData = data.map(item => {
		const { compulsory, commercial } = item
		return {
			...item,
			compulsory: compulsory
				? {
						...compulsory,
						dateStart:
							compulsory.status == 10
								? compulsory.dateStart
								: dayjs(compulsory.dateEnd).add(1, 'day').format('YYYY-MM-DD'),
						dateEnd:
							compulsory.status == 10
								? compulsory.dateEnd
								: dayjs(compulsory.dateEnd).add(1, 'year').format('YYYY-MM-DD'),
						timeChanged: false,
				  }
				: {
						carInsuranceId: '',
						insuranceCompanyId: '',
						dateStart: '',
						dateEnd: '',
						annexesArr: [],
						type: '',
						timeChanged: false,
				  },
			commercial: commercial
				? {
						...commercial,
						dateStart:
							commercial.status == 10
								? commercial.dateStart || undefined
								: commercial.dateStart
								? dayjs(commercial.dateEnd).add(1, 'day').format('YYYY-MM-DD')
								: undefined,
						dateEnd:
							commercial.status == 10
								? commercial.dateEnd || undefined
								: commercial.dateEnd
								? dayjs(commercial.dateEnd).add(1, 'year').format('YYYY-MM-DD')
								: undefined,
						timeChanged: false,
				  }
				: {
						carInsuranceId: '',
						insuranceCompanyId: '',
						dateStart: '',
						dateEnd: '',
						annexesArr: [],
						type: '',
						timeChanged: false,
				  },
			// clivtaDateStart:
			// 	item.compulsoryInsuranceStatus == 10
			// 		? item.clivtaDateStart
			// 		: dayjs(item.clivtaDateEnd).add(1, 'day').format('YYYY-MM-DD'),
			// clivtaDateEnd:
			// 	item.compulsoryInsuranceStatus == 10
			// 		? item.clivtaDateEnd
			// 		: dayjs(item.clivtaDateEnd).add(1, 'day').add(1, 'year').format('YYYY-MM-DD'),

			// carInsuranceDateStart:
			// 	item.commercialInsuranceStatus == 10
			// 		? item.carInsuranceDateStart || undefined
			// 		: item.carInsuranceDateStart
			// 		? dayjs(item.carInsuranceDateEnd).add(1, 'day').format('YYYY-MM-DD')
			// 		: undefined,
			// carInsuranceDateEnd:
			// 	item.commercialInsuranceStatus == 10
			// 		? item.carInsuranceDateEnd || undefined
			// 		: item.carInsuranceDateEnd
			// 		? dayjs(item.carInsuranceDateEnd).add(1, 'day').add(1, 'year').format('YYYY-MM-DD')
			// 		: undefined,
		}
	})
	console.log('tableData', tableData)
	setTable(tableData)
	setForm({ tableData })
	// setCompany(data.map(item => ({ label: item.name, value: item.id })))
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.bold {
	font-weight: 600;
}
.form-item {
	margin-top: 20px;
}
.table-wrap {
	border: 1px solid#ebeef5;
	// .thead {
	// 	width: 100%;
	// 	height: 40px;
	// 	line-height: 40px;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	color: #909399;
	// 	font-size: 14px;
	// 	font-weight: 600;
	// 	border-bottom: 1px solid #ebeef5 !important;
	// 	box-sizing: border-box;
	// 	.title-item {
	// 		text-align: center;
	// 		border-right: 1px solid #ebeef5;
	// 		box-sizing: border-box;
	// 		&:last-child {
	// 			border-right: none;
	// 		}
	// 	}
	// }
	.table-content {
		width: 100%;
		overflow: auto;
		.row-item {
			width: 100%;
			// height: 220px;
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			border-bottom: 1px solid #ebeef5;
			.column-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				// padding: 8px 0;
				height: 100%;
				text-align: center;
				border-right: 1px solid #ebeef5;
				box-sizing: border-box;
				&:last-child {
					border-right: none;
				}
				.part-item {
					width: 100%;
					height: 110px;
					padding: 0 12px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					box-sizing: border-box;
					border-bottom: 1px solid #ebeef5;
					&.full-part {
						height: 220px;
					}
					&.title-item {
						width: 100%;
						height: 40px;
						line-height: 40px;
						color: #909399;
						font-size: 14px;
						font-weight: 600;
						box-sizing: border-box;
						text-align: center;
					}
				}
			}
		}
	}
	.width_100 {
		width: 100px;
		flex: 0 0 100px;
	}
	.width_180 {
		width: 180px;
		flex: 0 0 180px;
	}
	.width_480 {
		width: 480px;
		flex: 0 0 480px;
	}
}
/deep/ .el-form-item {
	margin-bottom: 0 !important;
}
/deep/ .uploader-button {
	width: 80px !important;
	height: 80px !important;
	margin-bottom: 0 !important;
}
/deep/ .uploaded-image-box {
	width: 80px !important;
	height: 80px !important;
	margin-bottom: 0 !important;
	margin-right: 10px !important;
}
/deep/ .image-content,
/deep/.file-content {
	width: 80px !important;
	height: 80px !important;
}
</style>
