<template>
	<div class="container">
		<label-item title="基础信息" :list="basicData" />
		<label-item title="其它信息" :list="otherData" />
		<div style="font-size: 14px; display: flex; flex-direction: row; margin-top: -10px">
			<p style="color: #707070">备注：</p>
			<p style="color: #292929; width: 580px">
				{{ _.data.remark }}
			</p>
		</div>
		<label-item title="车辆证件" />
		<div v-for="(img, i) in imgList" :key="i" class="item">
			<span class="img-title">{{ img.title }}：</span>
			<template v-if="(img.list && img.list.length) || (img.files && img.files.length)">
				<div class="img-info-content" v-if="img.list && img.list.length">
					<img @click="show(img.list)" class="image" :src="img.list ? img.list[0] : ''" />
					<p class="amount">{{ img.list.length }}张</p>
				</div>

				<div v-if="img.files && img.files.length" style="margin-left: 12px">
					<div v-for="(file, index) in img.files" :key="index">
						<el-tooltip
							class="item"
							effect="dark"
							placement="bottom"
							:content="fileNameFilter(file)"
						>
							<div class="file-info">
								<img
									style="height: 100px; width: 100px"
									:src="getFileTypeDefaultImage(file, file)"
									@click="onOpenFile(file)"
								/>
							</div>
						</el-tooltip>
					</div>
				</div>
			</template>

			<span v-else class="default-text">暂无{{ img.title }}</span>
		</div>

		<el-image-viewer
			v-if="visible"
			:url-list="images"
			hide-on-click-modal
			@close="visible = false"
		/>
	</div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue'
import dayjs from 'dayjs'
import labelItem from './label'
import { getFileTypeDefaultImage } from '@/components/YiFileUploader/utils'

const _ = defineProps({
	data: {
		type: Object,
		default: () => ({}),
	},
})
const visible = ref(false)
const basicData = computed(() => [
	{ label: '车辆车型', value: _.data.carModelName },
	{ label: '车辆颜色', value: _.data.carColor },
	{ label: '车辆车牌', value: _.data.licensePlateNum },
	{ label: '自编号', value: _.data.carNum },
	{ label: '发动机号', value: _.data.engineNum },
	{ label: '车架号', value: _.data.vin },
	{ label: 'GPS设备号', value: _.data.gpsNum },
	{ label: '备用GPS', value: _.data.standbyGpsNum },
	{ label: '逾期检验强制报废期止', value: _.data.mandatoryObsolescenceDate },
	{ label: '车辆型号', value: _.data.carModel },
	{ label: '车辆类型', value: _.data.carType },
	{ label: '使用性质', value: _.data.useNature },
])
const otherData = computed(() => [
	{ label: '交强险保险公司', value: _.data.clivtaInsuranceCompanyName },
	{
		label: '交强险日期',
		value: _.data.clivtaDateStart ? _.data.clivtaDateStart + ' 至 ' + _.data.clivtaDateEnd : '--',
	},
	{ label: '商业险保险公司', value: _.data.carInsuranceCompanyName || '--' },
	{
		label: '商业险日期',
		value: _.data.carInsuranceDateStart
			? _.data.carInsuranceDateStart + ' 至 ' + _.data.carInsuranceDateEnd
			: '--',
	},
	{
		label: '年审截止日期',
		value: _.data.annualAuditDateEnd || '--',
	},
	{
		label: '车辆注册时间',
		value: _.data.registerDate ? dayjs(_.data.registerDate).format('YYYY-MM-DD') : '--',
	},
])
const images = ref([])
const divideFile = list => {
	if (!list || !Array.isArray(list)) return { imgList: [], pdfList: [] }
	const imgList = list.filter(item => !item.includes('.pdf')) || []
	const pdfList = list.filter(item => item.includes('.pdf')) || []
	return { imgList, pdfList }
}
const imgList = computed(() => [
	{ title: '车辆行驶证', list: _.data.drivingLicenseList },
	{ title: '车辆营运证', list: _.data.optCertificateList },
	{
		title: '机动车登记证',
		list: divideFile(_.data.mvrcList).imgList,
		files: divideFile(_.data.mvrcList).pdfList,
	},
	{
		title: '车辆合格证',
		list: divideFile(_.data.vehicleCertificateList).imgList,
		files: divideFile(_.data.vehicleCertificateList).pdfList,
	},
	{
		title: '交强险保单',
		list: divideFile(_.data.compulsoryAnnexesArr).imgList,
		files: divideFile(_.data.compulsoryAnnexesArr).pdfList,
	},
	{
		title: '商业险保单',
		list: divideFile(_.data.commercialAnnexesArr).imgList,
		files: divideFile(_.data.commercialAnnexesArr).pdfList,
	},
	{ title: '其它证件', list: _.data.otherCertificateList },
])
function fileNameFilter(file) {
	try {
		const fileSuffixArr = file.split('/')
		const fileName = fileSuffixArr[fileSuffixArr.length - 1].replace(/&/g, '')
		return fileName
	} catch (e) {
		return '暂无文件名信息!'
	}
}

function onOpenFile(url) {
	window.open(url, '_blank')
}

function show(list) {
	images.value = list
	visible.value = true
}
</script>

<style lang="less" scoped>
.item {
	.flexible(row, flex-start, flex-start);
	margin-bottom: 20px;
	.img-title {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.64);
		width: 100px;
	}

	.default-text {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.64);
	}
	.img-info-content {
		position: relative;
		// width: 100px;
		// height: 100px;
		// margin-left: 10px;
		border-radius: 4px;
		box-shadow: 0 0 0 1px rgba(2229, 229, 229, 0.8);
		cursor: pointer;
		.image {
			height: 100px;
			width: 100px;
			border-radius: 4px;
		}
		.amount {
			position: absolute;
			bottom: -13px;
			left: 0;
			width: 100px;
			height: 20px;
			color: #ffffff;
			text-align: center;
			font-size: 14px;
			z-index: 100;
			background-color: rgba(0, 0, 0, 0.5);
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}
}
.viewer {
	display: none;
}
</style>
<style>
.el-overlay {
	z-index: 2014 !important;
}
</style>
