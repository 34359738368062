<template>
	<div class="container" v-loading="isLoading">
		<label-item title="操作记录"></label-item>
		<yi-table table-height="100%" :columns="columns" :data="tableData"></yi-table>
	</div>
</template>

<script setup>
import { defineProps, defineExpose, inject, onMounted } from 'vue'
import { useState, useFetch } from '@/utils/hooks.js'
import { operationRecords } from '@/api/vehicle'
import labelItem from './label'

const _ = defineProps({
	data: Object,
})
const $message = inject('$message')

const [tableData, setTable] = useState([])
const { isLoading, fetchData } = useFetch()

const columns = [
	{
		prop: 'createByName',
		label: '操作人',
		width: '100px',
	},
	{
		prop: 'createTime',
		label: '操作时间',
		width: '180px',
	},
	{
		prop: 'content',
		label: '操作内容',
	},
]
async function fetchList() {
	const { err, data } = await fetchData(operationRecords, {
		logKey: _.data.id,
		page: { current: 1, size: 99 },
	})
	if (err) $message.error(err.message)
	setTable(data.records.map(item => JSON.parse(item.logContent)))
}

function init() {
	fetchList()
}
onMounted(() => {
	init()
})
defineExpose({ init })
</script>

<style lang="less" scoped>
.container {
	.click {
		font-size: 14px;
		color: #2d8cf0;
		cursor: pointer;
		margin-left: 12px;
	}
}
</style>
